import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ConsignmentPlacePage from './views/cp/consignment_place';
import QnaSameDayPage from './views/qna/sameday/qna_sameday';
import CollectionDatePage from './views/cd/collection_date';

class Root extends Component {

    render() {
        return <BrowserRouter>
            <Switch>
                <Route exact path="/cp/:orderNumber" component={ConsignmentPlacePage} />
                <Route exact path="/qna/:orderNumber" component={QnaSameDayPage} />
                <Route exact path="/cd/:orderNumber" component={CollectionDatePage} />
            </Switch>
        </BrowserRouter>
    };
}

export default Root;